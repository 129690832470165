export const COLOR_MAP = {
  accent: {
    black: '#000000',
    blue: '#6468CA',
    green: '#6BCBA9',
    red: '#DE4141',
    orange: '#F46C20',
    gold: '#CBAD73',
    lightGrey: '#DDDDDD',
    grey: '#AAAAAA',
    darkGrey: '#666666',
    lightBlue: '#2976EA',
    lighterBlue: '#CEE9F1',
    white: '#FFFFFF',
    grey10: '#E6E6E6',
    grey20: '#E8E8E8',
    grey01: '#0000001A',
    lighterRed: '#FDD2D2',
    darkBlue: '#172B85',
    blueV2: '#5D9AF5',
  },
  scroll: {
    grey: '#D9D9D9',
    greyBrown: '#7C7059',
  },
  background: {
    white: '#FFFFFF',
    black: '#000000',
    lightBlack: '#1C1D21',
    grey: '#666666',
    greyShade2: '#F9F9F9',
    darkGrey: '#1C1D21',
    lightGrey: '#F8F8F8',
    brandColor: '#CBAD73',
    SnowWhite: '#F5EFE3',
    brandColorSnow: '#CBAD7333',
    disabled: '#E8E8E8',
    brandColorSnow10: '#CDAD731A',
    brandColor10: '#FAF7F1',
    brandColorSoftV1: '#E2D1B6',
    brandColorSoftV2: '#ECE0CE',
    brandColorSoftV3: '#cbad731a',
    brandColorSoftV4: '#FAF7F1',
    greyShade1: '#F3F0F0',
    transparentGray: '#0000001a',
    separatorColorOne: '#cbad7366',
    lightGreyV2: '#DDDDDD',
    lightGray2: '#F9F9F9',
  },
  text: {
    black: '#000000',
    darkGrey: '#666666',
    grey: '#AAAAAA',
    lightGrey: '#DDDDDD',
    whiteDisabled: '#FFFFFF19',
    white: '#FFFFFF',
    brandColor: '#CBAD73',
    orange: '#F46C20',
    lightBlue: '#2976EA',
    light: '#FBFAF5',
    green: '#2DA378',
  },
  controls: {
    placeholder: '#888888',
    normal: '#020202',
    disabled: '#777777',
    textAndIcon: '#FFFFFF',
    fillNormal: '#FFFFFF',
    fillDisabled: '#E8E8E8',
    clickableNormal: '#CBAD73',
    clickableHover: '#B09767',
    clickablePressed: '#B4904D',
    clickableDisabled: '#DCD0B9',
    strokeNormal: '#C8C8C8',
    strokeHover: '#B4B4B4',
    strokeActive: '#888888',
    buttonBlueNormal: '#2976EA',
    buttonBlueHover: '#2A6DD1',
    buttonBlueActive: '#275FB2',
    fillNormalV2: '#E2D1B6',
    clickableHoverV2: '#E2D1B6',
    clickablePressedV2: '#CBAD73',
    clickableDisabledV2: '#DDDDDD',

    dark: {
      clickable: '#020202',
      clickableHover: '#888888',
      clickablePressed: '#666666',
      clickableDisabled: '#DDDDDD',
    },
    branded: {
      clickable: '#CBAD73',
      clickableHover: '#B09767',
      clickablePressed: '#B4904D',
      clickableDisabled: '#DCD0B9',
    },
    light: {
      clickable: '#FFFFFF',
      clickableHover: '#EEEEEE',
      clickablePressed: '#CFCFCF',
      clickableDisabled: '#7E7E7E',
    },
    notification: {
      clickable: '#F5EFE3',
    },
    skeleton: {
      content1: '#F3F2EE',
      content2: '#E9DFCE',
      sidebar1: '#313236',
      sidebar2: '#3E3C37',
    },
  },
  gradient: {
    cardGradient1: '#51977A',
    cardGradient2: '#2BBC99',
    bannerGradient1: '#EEB41E',
    bannerGradient2: '#F46C20',
  },
};
