/* eslint-disable react-hooks/exhaustive-deps */
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { QUERY_KEY } from '../../common';
import { useDeleteDraftMutate } from '../../api/product/hooks/useDeleteDraftMutate';
import { Button, SecondButton } from '../../componentUI/simple/Button';
import { LoaderCertificate } from '../../common/icons/SvgInline/SvgIcons_1';
import { COLOR_MAP } from '../../componentUI/colorsMap';
import { Title, TITLE_SIZE, TITLE_WEIGHT } from '../../componentUI/simple/Title';
import { WarningDeletingDraftStyled } from './styled';
import { Props } from './types';

export const WarningDeletingDraft = ({
  onClose,
  onOpen,
  idProduct,
  allChecksDraftsElements,
  setAllChecksDraftsElements,
}: Props) => {
  const { t } = useTranslation();
  const client = useQueryClient();

  const {
    mutate: deleteDraft,
    isSuccess: isSuccessDeleteDraft,
    isLoading: isLoadingDeleteDraft,
  } = useDeleteDraftMutate();

  useEffect(() => {
    if (setAllChecksDraftsElements && isSuccessDeleteDraft) {
      setAllChecksDraftsElements(null);
      client.invalidateQueries({ queryKey: [QUERY_KEY.getStatistics] });
      client.invalidateQueries({ queryKey: [QUERY_KEY.getAuthenticationsTabletProduct] });
      onClose();
      return;
    }

    if (onOpen && isSuccessDeleteDraft) {
      client.invalidateQueries({ queryKey: [QUERY_KEY.getStatistics] });
      client.invalidateQueries({ queryKey: [QUERY_KEY.getAuthenticationsTabletProduct] });
      onOpen();
      onClose();
      return;
    }

    if (isSuccessDeleteDraft) {
      client.invalidateQueries({ queryKey: [QUERY_KEY.getStatistics] });
      client.invalidateQueries({ queryKey: [QUERY_KEY.getAuthenticationsTabletProduct] });
      onClose();
    }
  }, [isSuccessDeleteDraft]);

  return (
    <WarningDeletingDraftStyled>
      <Title style={{ textAlign: 'center' }} size={TITLE_SIZE.h4} weight={TITLE_WEIGHT.normal}>
        {t('warningDeletingDraft.title')}
      </Title>

      <Button
        onClick={() => {
          if (allChecksDraftsElements && Object.values(allChecksDraftsElements).includes(true)) {
            const keys = Object.keys(allChecksDraftsElements);

            const isSelectDraftElement = keys.reduce<Array<string>>((acc, element) => {
              if (allChecksDraftsElements[element]) {
                return [...acc, element];
              }
              return acc;
            }, []);

            deleteDraft(isSelectDraftElement);
            return;
          }

          if (idProduct) {
            deleteDraft(idProduct);
          }
        }}
        isStretch
        disabled={isLoadingDeleteDraft}
      >
        {isLoadingDeleteDraft ? (
          <LoaderCertificate size="22" color={COLOR_MAP.accent.white} />
        ) : (
          <>{t('warningDeletingDraft.deleteButton')}</>
        )}
      </Button>

      <SecondButton onClick={() => onClose()} isStretch disabled={isLoadingDeleteDraft}>
        {t('warningDeletingDraft.goBackButton')}
      </SecondButton>
    </WarningDeletingDraftStyled>
  );
};
