import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next'; // Import useTranslation from react-i18next
import { DropdownIndicator } from '../../../../../../../../common';
import { usePrevious } from '../../../../../../../../hooks';
import { useGetBrandsListQuery } from '../../../../../../../../api/brands/hooks';
import { useCheckStatusesQuery } from '../../../../../../../../api/product/hooks/useCheckStatusesQuery';
import { sortArrayOfObjectsForAscByField } from '../../../../../../../../helpers/sortArrayOfObjectsForAscByField';
import { getOptionsFilterSelectTypeTwo, objectFormation } from '../../helpers';
import { DropDownListType, NAME_OBJECT_GET_PRODUCT } from '../../types';
import { FilterSelectTypeTwoStyled, stylesForControl, stylesForPlaceholder } from './styled';
import { OptionsBrandType, Props } from './types';

const { brand } = NAME_OBJECT_GET_PRODUCT;

export const FilterSelectTypeTwo = ({ selectDropDownList, setSelectedSetFilters }: Props) => {
  const { t } = useTranslation();
  const { dropDownList, id, nameObject } = selectDropDownList;

  const isBrandSelect = nameObject === brand;

  const { data: dataBrandsList, isLoading: isLoadingBrandsList } = useGetBrandsListQuery();
  const { data: dataCheckStatuses, isLoading: isLoadingCheckStatuses } = useCheckStatusesQuery();

  const isLoadingQuery = isLoadingCheckStatuses || isLoadingBrandsList;

  const prevNameObject = usePrevious(nameObject);

  const [isNewMasterDropdownList, setIsNewMasterDropdownList] = useState(false);

  const [selectValue, setSelectValue] = useState<OptionsBrandType | null>(null);
  const [includeValue, setIncludeValue] = useState<DropDownListType>(dropDownList[0]);

  const optionsDropDownList: Array<OptionsBrandType> = useMemo(() => {
    const options = getOptionsFilterSelectTypeTwo({ dataBrandsList, dataCheckStatuses, nameObject });

    return options.sort(sortArrayOfObjectsForAscByField('label'));
  }, [dataBrandsList, dataCheckStatuses, nameObject]);

  useEffect(() => {
    if (!selectValue?.value) {
      return;
    }

    setSelectedSetFilters((prev) => {
      const settingSelect = objectFormation({
        nameObject,
        idFilter: id,
        parameterOne: selectValue?.value || '',
        parameterTwo: includeValue?.value || false,
      });

      return {
        ...prev,
        ...settingSelect,
      };
    });
  }, [selectValue, includeValue]);

  useEffect(() => {
    if (!nameObject || !prevNameObject) {
      return;
    }

    setSelectValue(null);
    setIncludeValue(dropDownList[0]);
    setIsNewMasterDropdownList(true);
  }, [nameObject]);

  useEffect(() => {
    if (isNewMasterDropdownList) {
      setSelectedSetFilters((prev) => {
        const settingSelect = objectFormation({
          nameObject,
          idFilter: id,
          parameterOne: selectValue?.value || '',
          parameterTwo: includeValue?.value || false,
        });

        return {
          ...prev,
          ...settingSelect,
        };
      });
      setIsNewMasterDropdownList(false);
    }
  }, [isNewMasterDropdownList]);

  return (
    <FilterSelectTypeTwoStyled>
      <Select
        styles={{
          control: (base) => ({ ...base, ...stylesForControl }),
        }}
        components={{ DropdownIndicator }}
        options={dropDownList}
        classNamePrefix="custom-select"
        value={includeValue}
        onChange={(option) => {
          if (option) {
            setIncludeValue(option);
          }
        }}
        isSearchable={false}
      />

      <Select
        styles={{
          placeholder: (base) => ({ ...base, ...stylesForPlaceholder }),
          control: (base) => ({ ...base, ...stylesForControl }),
        }}
        isDisabled={isLoadingQuery}
        components={{ DropdownIndicator }}
        options={optionsDropDownList}
        classNamePrefix="custom-select"
        placeholder={
          isBrandSelect
            ? t('authenticationsTablet.filterSelectTypeTwo.startTypingBrand')
            : t('authenticationsTablet.filterSelectTypeTwo.selectOption')
        }
        value={selectValue}
        onChange={(option) => {
          if (option) {
            setSelectValue(option);
          }
        }}
      />
    </FilterSelectTypeTwoStyled>
  );
};
