/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormField } from '../../../../../../hooks';
import { InputWithError } from '../../../../../../componentUI/complex/InputWithError';
import { emptyStringCheck, textErrorCheck } from '../../../../../../helpers';
import { CompanyInformationBlockStyled, WrapperCityPostalStated } from './styled';
import { Props } from './types';

export const BusinessForm = ({ setFormData, setErrorBusinessForm, isEU, postalRegex, isSelectState }: Props) => {
  const { t } = useTranslation();

  const [companyName, setCompanyName, companyNameError, setCompanyNameError] = useFormField({
    initValue: '',
    withError: true,
  });

  const [addressOne, setAddressOne, addressOneError, setAddressOneError] = useFormField({
    initValue: '',
    withError: true,
  });

  const [city, setCity, cityError, setCityError] = useFormField({
    initValue: '',
    withError: true,
  });

  const [postalCode, setPostalCode, postalCodeError, setPostalCodeError] = useFormField({
    initValue: '',
    withError: true,
  });

  const [addressTwo, setAddressTwo] = useState('');
  const [stateOrProvince, setStateOrProvince] = useState('');
  const [vatNumber, setVatNumber] = useState('');

  const validatePostalCode = (postalCode: string, regExp: string | undefined) => {
    if (!postalCode) {
      setPostalCodeError(t('auth.completionRegistration.businessForm.errors.postalCode'));
    } else {
      setPostalCodeError('');
    }

    if (regExp) {
      const regex = new RegExp(regExp);

      if (!regex.test(postalCode)) {
        setPostalCodeError(t('auth.completionRegistration.businessForm.errors.postalCode'));
      } else {
        setPostalCodeError('');
      }
    }
  };

  const changeCompanyName = (value: string) => {
    setCompanyNameError(value ? '' : t('auth.completionRegistration.businessForm.errors.companyName'));
    setCompanyName(value);
  };

  const changeAddressOne = (value: string) => {
    setAddressOneError(value ? '' : t('auth.completionRegistration.businessForm.errors.addressOne'));
    setAddressOne(value);
  };

  const changeCity = (value: string) => {
    setCityError(value ? '' : t('auth.completionRegistration.businessForm.errors.city'));
    setCity(value);
  };

  const changePostalCode = (value: string) => {
    setPostalCodeError('');
    setPostalCode(value);
  };

  const onBlurPostalCode = (value: string) => {
    validatePostalCode(value, postalRegex);
  };

  // передаем актуальные данные которые ввел пользователь
  useEffect(() => {
    setFormData((prev) => {
      return { ...prev, companyName, addressOne, addressTwo, city, postalCode, stateOrProvince, vatNumber };
    });
  }, [companyName, addressOne, addressTwo, city, postalCode, stateOrProvince, vatNumber]);

  // проверяем корректность ввода данных и если они не корректны
  useEffect(() => {
    const isEmptyString = emptyStringCheck([companyName, addressOne, city, postalCode]);
    const isError = textErrorCheck([companyNameError, addressOneError, cityError, postalCodeError]);

    if (isEmptyString || isError) {
      setErrorBusinessForm(true);
      return;
    }

    setErrorBusinessForm(false);
  }, [companyName, addressOne, city, postalCode, companyNameError, addressOneError, cityError, postalCodeError]);

  return (
    <>
      <CompanyInformationBlockStyled>
        <InputWithError
          value={companyName}
          errorMessage={companyNameError}
          onChange={(event) => changeCompanyName(event)}
          onBlur={(event) => changeCompanyName(event.currentTarget.value)}
          placeholder={t('auth.completionRegistration.businessForm.placeholders.companyName')}
        />

        <InputWithError
          value={addressOne}
          errorMessage={addressOneError}
          onChange={(event) => changeAddressOne(event)}
          onBlur={(event) => changeAddressOne(event.currentTarget.value)}
          placeholder={t('auth.completionRegistration.businessForm.placeholders.addressOne')}
        />

        <InputWithError
          value={addressTwo}
          onChange={setAddressTwo}
          placeholder={t('auth.completionRegistration.businessForm.placeholders.addressTwo')}
        />

        <WrapperCityPostalStated>
          <InputWithError
            value={city}
            errorMessage={cityError}
            onChange={(event) => changeCity(event)}
            onBlur={(event) => changeCity(event.currentTarget.value)}
            placeholder={t('auth.completionRegistration.businessForm.placeholders.city')}
            maxLength={20}
          />

          <InputWithError
            value={postalCode}
            errorMessage={postalCodeError}
            onChange={(event) => changePostalCode(event)}
            onBlur={(event) => onBlurPostalCode(event.currentTarget.value)}
            placeholder={t('auth.completionRegistration.businessForm.placeholders.postalCode')}
            maxLength={20}
          />

          {!isSelectState && (
            <InputWithError
              value={stateOrProvince}
              onChange={setStateOrProvince}
              placeholder={t('auth.completionRegistration.businessForm.placeholders.stateOrProvince')}
              maxLength={20}
            />
          )}
        </WrapperCityPostalStated>

        {isEU && (
          <InputWithError
            value={vatNumber}
            onChange={(value) => setVatNumber(value)}
            placeholder={t('auth.completionRegistration.businessForm.placeholders.vatNumber')}
            errorMessage=""
          />
        )}
      </CompanyInformationBlockStyled>
    </>
  );
};
