/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForgotPasswordMutate } from '../../../../api/auth/hooks';
import { useFormField, useIsMobile } from '../../../../hooks';
import { Button } from '../../../../componentUI/simple/Button';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { TAG_NAME } from '../../../../componentUI/constants';
import { useGoogleAnalytics } from '../../../../analytics';
import { CheckPassword, Loader, TermsBlock, ThemeEnum } from '../../../../components';
import { AuthContainer } from '../../Auth';
import { LogoBlock, TitleBlock } from '../../components';
import { ScreenResetPassSetNewSuccess } from '../../components/InformationScreens';

import {
  LeftColumnStyled,
  LinkTextStyled,
  RightColumnStyled,
  WrapperInputStyled,
  WrapperResetPasswordStyled,
  WrapperSignInStyled,
} from './styled';

export const ResetPasswordRedesign = () => {
  useGoogleAnalytics();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { hash: hashPassword } = useParams();

  const {
    mutate: forgotPassword,
    isSuccess: isSuccessForgotPasswordData,
    isError: isErrorForgotPasswordData,
    isLoading: isLoadingForgotPasswordData,
  } = useForgotPasswordMutate();

  const [successChange, setSuccessChange] = useState(false);

  const [newPassword, setNewPassword, newPasswordError, setNewPasswordError] = useFormField({
    initValue: '',
    withError: true,
  });
  const [confirmPassword, setConfirmPassword, confirmPasswordError, setConfirmPasswordError] = useFormField({
    initValue: '',
    withError: true,
  });

  useEffect(() => {
    if (isSuccessForgotPasswordData) {
      setSuccessChange(true);
    }

    if (isErrorForgotPasswordData) {
      setSuccessChange(false);
    }
  }, [isSuccessForgotPasswordData, isErrorForgotPasswordData]);

  if (isLoadingForgotPasswordData) {
    return <Loader style={{ margin: '45vh auto' }} />;
  }

  const isDisabledSetNewPassword = Boolean(
    !newPassword || !confirmPassword || confirmPasswordError || isLoadingForgotPasswordData || newPasswordError,
  );

  return (
    <AuthContainer>
      <RightColumnStyled>
        <LogoBlock />
        {successChange && <ScreenResetPassSetNewSuccess />}

        {!successChange && (
          <WrapperResetPasswordStyled>
            <TitleBlock titleText={t('auth.resetPassword.title')} subtitleText={t('auth.resetPassword.subtitle')} />

            <WrapperInputStyled>
              <CheckPassword
                newPassword={newPassword}
                setNewPassword={setNewPassword}
                newPasswordError={newPasswordError}
                setNewPasswordError={setNewPasswordError}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                confirmPasswordError={confirmPasswordError}
                setConfirmPasswordError={setConfirmPasswordError}
                designTheme={ThemeEnum.white}
                placeholderText={{
                  placeholderOne: t('auth.resetPassword.placeholderOne'),
                  placeholderTwo: t('auth.resetPassword.placeholderTwo'),
                }}
              />

              <Button
                onClick={() => forgotPassword({ password: newPassword, hash: hashPassword || '' })}
                isStretch
                disabled={isDisabledSetNewPassword}
              >
                {t('auth.resetPassword.setNewPasswordButton')}
              </Button>
            </WrapperInputStyled>

            <WrapperSignInStyled>
              <LinkTextStyled
                tag={TAG_NAME.span}
                colorText={COLOR_MAP.text.brandColor}
                onClick={() => {
                  navigate('/signin');
                }}
              >
                {t('auth.resetPassword.signInLinkText')}
              </LinkTextStyled>
            </WrapperSignInStyled>
          </WrapperResetPasswordStyled>
        )}
        <TermsBlock />
      </RightColumnStyled>

      {!isMobile && <LeftColumnStyled />}
    </AuthContainer>
  );
};
