/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGoogleAnalytics } from '../../../../analytics';
import { useUpdateMeMutate } from '../../../../api/auth/hooks';
import { Loader, TermsBlock } from '../../../../components';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { Button, BUTTON_SIZE } from '../../../../componentUI/simple/Button';
import { RadioChips } from '../../../../componentUI/simple/Radio';
import { Text, TEXT_WEIGHT } from '../../../../componentUI/simple/Text';
import { CompletionRegistrationHeaderBlock, TitleBlock } from '../../components';
import { AdditionalBasicInfo, BusinessForm, UserFullName } from './components';
import { formingObjectEndpoint, getButtonText } from './helpers';
import { getButtonLockConditions } from './helpers/getButtonLockConditions';
import {
  CompletionRegistrationStyled,
  RadioWrapperStyled,
  WrapperAccountInfoStyled,
  WrapperLoaderStyled,
  WrappingInputFieldsStyled,
} from './styled';
import { AccountTypeEnum, FormDataType, StepNumberEnum } from './types';

const { business, personal } = AccountTypeEnum;
const { step1, step2 } = StepNumberEnum;

export const CompletionRegistration = () => {
  useGoogleAnalytics();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // содержит объект с заполненными полями формы Completion Registration
  const [formData, setFormData] = useState<FormDataType>({
    firstName: '',
    lastName: '',
    country: null,
    typeBusiness: '',
    companyName: '',
    addressOne: '',
    addressTwo: '',
    city: '',
    postalCode: '',
    stateOrProvince: '',
    vatNumber: '',
    countryStateId: '',
  });

  const [accountType, setAccountType] = useState<AccountTypeEnum | string>('');
  const [isEU, setIsEU] = useState<boolean | null>(null);
  const [postalRegex, setPostalRegex] = useState('');
  const [errorUserFullName, setErrorUserFullName] = useState(true);
  const [errorBusinessForm, setErrorBusinessForm] = useState(true);
  const [stateError, setStateError] = useState(false);
  const [countryError, setCountryError] = useState(true);
  const [businessError, setBusinessError] = useState(true);
  const [accountTypeError, setAccountTypeError] = useState(true);
  const [step, setStep] = useState<StepNumberEnum>(step1);

  const isBusinessAccount = accountType === business;
  const isPersonalAccount = accountType === personal;
  const isStep1 = step === step1;
  const isStep2 = step === step2;
  const isSelectState = Boolean(formData?.countryStateId);

  const {
    mutate: updateUserAccount,
    isSuccess: isSuccessUpdateUserAccountData,
    isLoading: isLoadingUpdateUserAccountData,
  } = useUpdateMeMutate();

  useEffect(() => {
    if (isSuccessUpdateUserAccountData) {
      navigate('/');
    }
  }, [isSuccessUpdateUserAccountData]);

  const isDisabledFinishBtn = getButtonLockConditions({
    typeAcc: accountType,
    selectStep: step,
    error: {
      errorUserFullName,
      errorBusinessForm,
      accountTypeError,
      countryError,
      businessError,
      stateError,
    },
  });

  return (
    <>
      <CompletionRegistrationStyled isLoading={isLoadingUpdateUserAccountData}>
        <CompletionRegistrationHeaderBlock />

        <WrapperAccountInfoStyled>
          <TitleBlock titleText={t('auth.completionRegistration.title')} subtitleColor={COLOR_MAP.text.black} />

          <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.brandColor}>
            {!isBusinessAccount && isStep1 && t('auth.completionRegistration.stepText.personal')}
            {isBusinessAccount && isStep1 && t('auth.completionRegistration.stepText.businessStep1')}
            {isBusinessAccount && isStep2 && t('auth.completionRegistration.stepText.businessStep2')}
          </Text>

          {isStep1 && <UserFullName setFormData={setFormData} setErrorUserFullName={setErrorUserFullName} />}

          <WrappingInputFieldsStyled>
            {isStep1 && (
              <RadioWrapperStyled>
                <RadioChips
                  key={t('auth.completionRegistration.checkpointUserAccountType.personal.title')}
                  name="AccountType"
                  label={t('auth.completionRegistration.checkpointUserAccountType.personal.title')}
                  descriptions={t('auth.completionRegistration.checkpointUserAccountType.personal.description')}
                  value="Personal"
                  checkedValue={accountType}
                  onChange={(value) => {
                    setAccountType(value as AccountTypeEnum);
                    setAccountTypeError(value === '');
                  }}
                />
                <RadioChips
                  key={t('auth.completionRegistration.checkpointUserAccountType.business.title')}
                  name="AccountType"
                  label={t('auth.completionRegistration.checkpointUserAccountType.business.title')}
                  descriptions={t('auth.completionRegistration.checkpointUserAccountType.business.description')}
                  value="Business"
                  checkedValue={accountType}
                  onChange={(value) => {
                    setAccountType(value as AccountTypeEnum);
                    setAccountTypeError(value === '');
                  }}
                />
              </RadioWrapperStyled>
            )}

            {isBusinessAccount && isStep2 && (
              <BusinessForm
                setFormData={setFormData}
                setErrorBusinessForm={setErrorBusinessForm}
                isEU={isEU}
                postalRegex={postalRegex}
                isSelectState={isSelectState}
              />
            )}

            {isStep1 && (
              <AdditionalBasicInfo
                setFormData={setFormData}
                setCountryError={setCountryError}
                setBusinessError={setBusinessError}
                setStateError={setStateError}
                setIsEU={setIsEU}
                setPostalRegex={setPostalRegex}
                isBusinessAccount={isBusinessAccount}
              />
            )}

            <Button
              size={BUTTON_SIZE.medium}
              isStretch
              disabled={isDisabledFinishBtn}
              onClick={() => {
                if (isBusinessAccount && isStep1) {
                  setStep(step2);
                  return;
                }

                if (isBusinessAccount && isStep2) {
                  updateUserAccount(formingObjectEndpoint({ accountType, formData }));
                  return;
                }

                if (isPersonalAccount && isStep1) {
                  updateUserAccount(formingObjectEndpoint({ accountType, formData }));
                }
              }}
            >
              {getButtonText(accountType, step)}
            </Button>
          </WrappingInputFieldsStyled>
        </WrapperAccountInfoStyled>

        <TermsBlock />
      </CompletionRegistrationStyled>

      {isLoadingUpdateUserAccountData && (
        <WrapperLoaderStyled>
          <Loader />
        </WrapperLoaderStyled>
      )}
    </>
  );
};
